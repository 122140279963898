<template>
    <div> 

        <IconTopTable />
        <div class="content-table-results mt-2">
            <table class="table table-fixed table-stripes">
                <ColumnColGroup page="result" :column="data.column" :default_column="data.default_column" />
                <ColumnThead page="result" :column="data.column" :default_column="data.default_column" />
                <tbody> 
                    <template v-if="data.data.data && data.data.data.length > 0">
                        <tr v-for="(item, key) in data.data.data" :key="key" >                
                            
                            <td>
                                <label class="table-check">
                                    <input type="checkbox" v-model="select_export" :value="item.drug_id">
                                    <span class="checkbox-table"></span>
                                </label>
                            </td>
                            <td>
                                <router-link :to="'/detail/'+item.drug_id+'/'+item.agency_model.toLowerCase()" target="_blank" rel="noopener">
                                    <div class="icon-fiche"></div>
                                </router-link>
                                <div v-if="isAdmin()">
                                    <a :href="modifyPage(item.agency_model.toLowerCase(), item.drug_id)" target="_blank" rel="noopener"> 
                                        <em class="icon-edit"></em>
                                    </a>
                                </div>
                            </td>
                            <template v-for="(column) in data.column" :key="column.code" >


                                <td v-if="column.Status && column.code == 'colombia_001'" :code="column.code">
                                    <span v-html="ema_id(item.links)"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_002'" :code="column.code">
                                    {{item.drug_id}}
                                </td>
                                <!-- <td v-else-if="column.Status && column.code == 'colombia_003'" :code="column.code">
                                    <span v-html="icon_link(item.drug_id, 'iets')"></span>
                                </td> -->
                                <td v-else-if="column.Status && column.code == 'colombia_004'" :code="column.code">
                                    {{check_empty(item.category)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_005'" :code="column.code">
                                    {{check_empty(item.dci_new)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_006'" :code="column.code">
                                    {{check_empty(DDMMMYY(item.colombia.opinion_date))}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_041'" :code="column.code">
                                    <span v-html="check_empty(overview_regulatory_status(item.current_ma_status))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_007'" :code="column.code">
                                    <span v-html="column_status(item)"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_008'" :code="column.code">
                                    {{check_empty(DDMMMYY(item.ma_date))}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_009'" :code="column.code">
                                    {{check_empty(item.name)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_010'" :code="column.code">
                                    {{check_empty(item.dci)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_011'" :code="column.code">
                                    {{check_empty(item.firm)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_012'" :code="column.code">
                                    <span v-html="check_empty(array(item.atc))"> </span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_013'" :code="column.code">
                                    <span v-html="check_empty(array(item['indexation_'+$i18n.locale]))"> </span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_014'" :code="column.code">
                                    {{check_empty(item.colombia.region)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_015'" :code="column.code">
                                    {{check_empty(item.colombia.agency)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_016'" :code="column.code">
                                    <span v-html="check_empty(decision(item.colombia.decision))"> </span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_017'" :code="column.code">
                                    <span v-html="reimbursement_decision(item.colombia.reimbursement_decision)"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_018'" :code="column.code">
                                    {{check_empty(item.prioritis_hta_index)}}
                                </td>       
                                <template v-else-if="column.Status && column.code == 'colombia_019'" colspan="4"  :code="column.code">
                                    <td :key="column.code+'_020'" :code="'colombia_020'">
                                        <Readmore :longText="item.target_population"/>
                                    </td>
                                    <td :key="column.code+'_021'" :code="'colombia_021'">
                                        <Readmore :longText="item.prescription_and_delivery_conditions"/>
                                    </td>
                                    <td :key="column.code+'_022'" :code="'colombia_022'">
                                        <Readmore :longText="item.access_scheme"/>
                                    </td>
                                    <td :key="column.code+'_023'" :code="'colombia_023'">
                                        <Readmore :longText="item.outcome_level"/>
                                    </td>
                                </template>
                                <td v-else-if="column.Status && column.code == 'colombia_024'" :code="column.code">
                                    <Readmore v-if="$i18n.locale == 'en'" :longText="item.rationale_and_comments_html_en"/>
                                    <Readmore v-else :longText="item.rationale_and_comments_html_original"/>
                                </td>   
                                <td v-else-if="column.Status && column.code == 'colombia_025'" :code="column.code">
                                    {{check_empty(item.colombia.guidance_number)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_026'" :code="column.code">
                                    <Readmore v-if="$i18n.locale == 'en'" :longText="item.indication_en"/>
                                    <Readmore v-else :longText="item.indication_original"/>
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_027'" :code="column.code">
                                    <span v-if="$i18n.locale === 'fr'" v-html="check_empty(array(item.classification_fr))"></span>
                                    <span v-else v-html="check_empty(array(item.classification_en))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_028'" :code="column.code">
                                    {{check_empty_with_locale(item.mk_orphelin)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_029'" :code="column.code">
                                    <span v-html="check_empty(treatment_line(item.treatment_line))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_030'" :code="column.code">
                                    {{check_empty(item.specificity)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'colombia_031'" :code="column.code">
                                    {{check_empty(item.administration)}}
                                </td>
                                <template v-else-if="column.Status && column.code == 'colombia_032'" colspan="4"  :code="column.code">
                                    <td :key="column.code+'_033'" :code="'colombia_033'">
                                        {{ check_empty(DDMMMYY(item.hta_process_date)) }}
                                    </td>
                                    <td :key="column.code+'_034'" :code="'colombia_034'">
                                        <span v-html="hta_process_status(item, 'hta2_')"></span>
                                    </td>
                                    <td :key="column.code+'_035'" :code="'colombia_035'">
                                        {{ check_empty(DDMMMYY(item.rembursement_process_date)) }}
                                    </td>
                                    <td :key="column.code+'_036'" :code="'colombia_036'">
                                        <span v-html="rembursement_status(item.reimbursement_decision, 'rb2_')"></span>
                                    </td>
                                </template>
                                <template v-else-if="column.Status && column.code == 'colombia_037'" colspan="3"  :code="column.code">
                                    <td :key="column.code+'_038'" :code="'colombia_038'">
                                        <span data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-html="hta_process_status_2(item)"></span>
                                    </td>
                                    <td :key="column.code+'_039'" :code="'colombia_039'">
                                        <span data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-html="rembursement_status_2(item)"></span>
                                    </td>
                                    <td :key="column.code+'_040'" :code="'colombia_040'">                                        
                                        <!-- <span v-html="datediff(item.ma_date, item.rembursement_process_date)"></span> -->
                                    </td>
                                </template>
                            </template>

                        </tr>
                    </template>

                    <template v-else><tr><td colspan="99">{{$t('No Data')}}</td></tr></template>

                </tbody>

            </table>
        </div>
        <ModalHTAstatus :item="item_hta_status" />      
    </div>
</template>

<script>

import IconTopTable from '@/components/result/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue';
import Readmore from '@/components/elements/readmore.vue';
import ModalHTAstatus from '@/components/result/modal_hta_status.vue'
import { isAdmin, column_status, hta_process_status, hta_process_status_2, rembursement_status, 
        rembursement_status_2, overview_regulatory_status, modifyPage, ema_id, icon_link, DDMMMYY, check_empty, 
        nct_number, treatment_line, datediff, array, check_empty_with_locale } from '@/utils'
export default {
    name: "Colombia",
    components: {
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        Readmore,
        ModalHTAstatus,
    },
    data : function() {
        return { 
            item_hta_status : {},
        }
    },
     computed: {                
        data(){
            return this.$store.getters['result/data'];
        },
        select_export: {
            set(val){ this.$store.dispatch("result/select_export", val) },
            get(){ 
                if(this.$store.getters['result/select_export_checkAll']){
                    return true
                }else{
                    return this.$store.getters['result/select_export'] ? this.$store.getters['result/select_export'].listID : null 
                }
            }
        }
    },
    methods: {
        overview_regulatory_status,
        hta_process_status,
        hta_process_status_2,
        rembursement_status,
        rembursement_status_2,
        column_status,
        isAdmin,
        ema_id,
        icon_link,
        DDMMMYY,
        check_empty,
        nct_number,
        treatment_line,
        datediff,
        array,
        check_empty_with_locale,
        decision(param){ 
            switch(param){
                case 'No cover':
                    return '<div style="color:#92D050;">'+param+'</div>'
                case 'Cover with evidence generation':
                    return '<div style="color:#088A68;">'+param+'</div>'
                case 'Cover with price negotiation':
                    return '<div style="color:#01A9DB;">'+param+'</div>'
                case 'Restrict coverage':
                    return '<div style="color:#ffc000;">'+param+'</div>'
                case 'Cover':
                    return '<div style="color:#FF0000;">'+param+'</div>'
                default:
                    return param
            } 
        },
		reimbursement_decision(param){
            switch(param){
                    case 'Include in the National Health Care System Plan':
                            return '<div style="color:#92D050;">'+param+'</div>'
                    case 'Not included in the National Health Care System Plan':
                            return '<div style="color:#FF0000;">'+param+'</div>'
                    default: 
                            return param
            }
        },	
        getResults(page = 1) {
            console.log(page)
        },
        hta_status_modal(item){
            this.item_hta_status = item
        },
        modifyPage
    }
}
</script>

<style scoped>
/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 70vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>