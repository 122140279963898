<template>
    <teleport to="body">
    <div class="modal modal-column fade" tabindex="-1" id="modal-config-column" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-custom" style="padding: 30px;">

                <div class="modal-head">
                    <h5 class="modal-title" id="exampleModalLabel">{{$t('Choisir les colonnes à afficher :')}}</h5>
                    <a type="button" class="close" style="opacity: 1;">
                        <span class="close-column" data-bs-dismiss="modal" aria-label="Close"></span>
                    </a>
                </div>
                
                <div class="modal-body p-0">
                    <div class="mb-4">
                        1. {{$t('Sélectionnez les rubriques que vous désirez afficher dans le tableau.')}}<br>
                        2. {{$t('Faites glisser les rubriques pour réorganiser les colonnes du tableau.')}}
                        <!-- <br>
                        <span>Checked names: {{ itemActive }}</span>
                        <br>
                        <span>draggableItems1: {{ draggableItems1 }}</span>
                        <br>
                        <span>draggableItems2: {{ draggableItems2 }}</span> -->
                    </div>
                    <div class="row">
                        <div class="col-6">

                            <draggable :list="draggableItems1" group="people" class="checkbox-list drag-item-list" v-bind="dragOptions" @end="onEnd" itemKey="code">                     
                                <template #item="{ element }">
                                    <template v-if="!element.parent || !element.parent.includes('overview')">
                                        <li class="drag-item">     
                                            <label class="group-checkbox" style="cursor: pointer;">
                                                <div class="dd-handle move">
                                                    <div class="icon-draggable"></div>
                                                </div>
                                                <input type="checkbox" v-model="itemActive" :value="element.code" :id="element.code" @click="selectChildren">
                                                <span class="icon-checkbox"  ></span>
                                                <div>{{getFullTitle(element)}}</div> 
                                                
                                            </label>
                                        </li>
                                    </template>
                                </template>
                            </draggable>

                            
                        </div>
                        <div class="col-6">
                            <draggable :list="draggableItems2" group="people" class="checkbox-list drag-item-list" v-bind="dragOptions" @end="onEnd" itemKey="code">                     
                                <template #item="{ element }">
                                    <template v-if="!element.parent || !element.parent.includes('overview')">
                                        <li class="drag-item">     
                                            <label class="group-checkbox" style="cursor: pointer;">
                                                <div class="dd-handle move">
                                                    <div class="icon-draggable"></div>
                                                </div>
                                                <input type="checkbox" v-model="itemActive" :value="element.code" :id="element.code" @click="selectChildren">
                                                <span class="icon-checkbox"  ></span>
                                                <div>{{getFullTitle(element)}}</div> 
                                            </label>
                                        </li>
                                    </template>
                                </template>
                            </draggable>
                            
                        </div>
                    </div>
                </div>
            
                <div class="bt-footer">
                    <button type="button" class="btn-border-orange" style=" margin-right: 40px" @click="resetColumn">{{$t('modal_column.reset')}}</button>
                    <button type="button" :class="'btn-orange ' + (canSave ? '' : ' btn-muted pointerNone')" @click="saveConfigColumn">{{$t('modal_column.save')}}</button>
                </div> 
            </div>
        </div>
    </div>
    </teleport>
</template>

<script>
import { Modal } from 'bootstrap'
import draggable from 'vuedraggable' 
export default {
    name : "ModalConfigColumn",
    emits: ["request_result_agency"],
    components:{
        draggable
    }, 
    data() {
        return { 
            draggableItems1 : [],
            draggableItems2 : [],
            itemActive : [],
            canSave : false
        }
    },
    mounted() {     
        if(this.data){
            this.setItemDraggable(this.data.column);
        }
        this.modal = new Modal(document.getElementById('modal-config-column'));
    },
    // updated(){
    //     this.setItemDraggable(this.data.column)
    // },
    methods: {
        setItemDraggable(data_column){ 
            this.itemActive = []
            const itemActive = []
            for(let i in data_column){ 
                if(data_column[i]['Status']){
                    itemActive.push(data_column[i].code)
                }
            }
            this.itemActive = itemActive;
             
            const half = Math.ceil(data_column.length / 2)
            const half_last = data_column.length - half
            this.draggableItems1 = data_column.slice(0, half)
            this.draggableItems2 = data_column.slice(-half_last)

        },
        getFullTitle(column, fullTitle = null) {
            let parent = this.data.default_column.find(item => item.code === column.parent)
            let title = (!fullTitle) ? column.text : fullTitle
            if(parent){
                title = parent.text + ' - ' + title
                return this.getFullTitle(parent, title)
            } else {
                if(column.parent === "0")
                    title = title.toUpperCase()
                return title
            }
        },
        resetColumn(){
            this.setItemDraggable(this.data.reset_column);
        },
        reload_data(){   
            this.$emit('request_result_agency', {page : 1})
        },
        saveConfigColumn(){
            if(this.canSave){
                let objColumn = {};
                let tempData = this.draggableItems1.concat(this.draggableItems2); 
                tempData.forEach((val) => {
                    if(this.itemActive.includes(val.code)){
                        objColumn[val.code] = true;
                    }else{
                        objColumn[val.code] = false;
                    }                    
                });
                this.$store.dispatch("result/saveColumn", { column : objColumn, agency : this.Agency, reset_column: this.data.reset_column} ).then(()=> {
                    this.modal.hide();
                });
                console.log('saveConfigColumn')
            }
        },
        checkCanSave(){
            let temp = {};
            let temp2 = {};
            if(this.data && this.data.column) {
                this.data.column.forEach((val) => {
                    temp[val.code] = val.Status;
                }); 
            }
            

            let tempData = this.draggableItems1.concat(this.draggableItems2); 
            tempData.forEach((val) => {
                if(this.itemActive.includes(val.code)){
                    temp2[val.code] = true;
                }else{
                    temp2[val.code] = false;
                }
                
            });
            if(JSON.stringify(temp) !== JSON.stringify(temp2)){
                this.canSave = true
            }else{
                this.canSave = false
            }
        },
        onEnd(){
            this.checkCanSave()
            console.log('onEnd')
        },
        selectChildren(e) {
            this.recursiveSelectChildren(e.target.checked, e.target.value)
            this.checkCanSave()
        },
        recursiveSelectChildren(checked, code) {
            if(checked && !this.itemActive.includes(code)) {
                this.itemActive.push(code)
            } else if(!checked && this.itemActive.indexOf(code) !== -1) {
                this.itemActive.splice(this.itemActive.indexOf(code), 1)
            }

            let tempData = this.draggableItems1.concat(this.draggableItems2);
            let children = tempData.filter(el => el.parent === code)
            if(children.length > 0){
                children.forEach((val) => {
                    this.recursiveSelectChildren(checked, val.code)
                }) 
            }
        }
    },
    computed: {
        Agency(){
            return this.$store.getters['result/Agency']
        },
        data(){
            return this.$store.getters['result/data']
        }, 
        load_agency(){
            return this.$store.getters['result/load_agency']
        }, 
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        overviewReimbursementStatus(){
            return this.$store.getters['result/overviewReimbursementStatus'];
        },
    },
    watch: {            
        itemActive: function(val) {
            console.log('watch  --- itemActive' + val)
            this.checkCanSave()
        },
        data: function() {
            if(this.data && this.data.column){
                this.setItemDraggable(this.data.column);
            }            
        },
        load_agency() {
            if(this.data && this.data.column)
                this.setItemDraggable(this.data.column);
        }
    },
   
}
</script>

<style scoped>
 
</style>