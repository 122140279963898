<template>
  <div class="box-ui-icon">
    <div class="fa-pull-left">
      <div class="col-icon" @click="selectExport">
        <em class="icon-view-section"></em>
        <span>{{$t('View selection')}}</span>
      </div>
      <div class="col-icon"  @click="clearExport">
        <em class="icon-delete"></em>
        <span>{{$t('Delete selection')}}</span>
      </div>
      <div class="col-icon" data-bs-toggle="modal" data-bs-target="#modal-config-column">
        <em class="icon-organise-section"></em>
        <a >
          {{$t('Show/Hide columns')}}
        </a>
      </div>
    </div>
    <div class="fa-pull-right">
      <div class="icon-right" v-if="!Array.isArray(data.data)">
        <pagination :data="data.data" @pagination-change-page="getResults" :limit="3"></pagination>
      </div>
      <!-- <div class="icon-right">
        <a class="btn-border-orange" style="padding: 6px 50px !important;">{{$t('Export')}}</a>
      </div> -->
      <div class="icon-right" @click="fullScreen">
        <img src="@/assets/images/icon/ic-fullview.svg" alt="" />
      </div>
      <IconTopTableLegend  />            
      <div v-if="canExport" class="icon-right main-overlay load-excel-overlay" @click="request_result_agency(data.sort_column, true)" >
        <img src="@/assets/images/icon-download.svg" alt="" />
        <div class="overlay" v-if="load_excel">
          <div class="loadding_circle" ></div>
        </div>
      </div>
      
      
    </div>
    <div class="clearfix"></div> 
    <ModalConfigColumn />
    
  </div>

  
 
</template>

<script>
import ModalConfigColumn from '@/components/result/modal_config_column.vue'
import pagination from '@/components/elements/pagination.vue';
import { Base64 } from 'js-base64'
import { currentDate } from '@/utils/index'
import IconTopTableLegend from '@/components/elements/icon_top_table_legend.vue'
export default {
  
  name: "icon_top_table",
  components: {     
    ModalConfigColumn,
    pagination,
    IconTopTableLegend
  },
  computed: {                
      data(){
          return this.$store.getters['result/data'];
      },
      Agency(){
        return this.$store.getters['result/Agency']
      },
      filters(){
          return this.$store.getters['result/filters'];
      },
      select_export_obj(){
        return this.$store.getters['result/select_export']
      },
      load_excel(){
        return this.$store.getters['result/load_excel']
      },      
      canExport(){            
          return this.$store.getters["users/export"];
      }
  },  
  methods: {
      request_result_agency(param = {}, exportExcel = false){
          let paramObj = {...this.$route.params, ...param, ...this.data.sort_column}              
          paramObj['agency'] = this.Agency
          paramObj['filter'] = Base64.encode(this.filters)

          if(this.select_export_obj && this.select_export_obj.isSelect){
              paramObj['select_export'] = this.select_export_obj.listID
          }            
          if(exportExcel){
            this.$store.dispatch(`result/export_excel`, [paramObj, currentDate()+"_"+(paramObj['agency'] == 'AtuFrance' ? 'AccesPrecoce' : paramObj['agency'])] )
          }else{
            this.$store.dispatch(`result/result_agency`, paramObj )
          }
          
          document.querySelector('.content-table-results').scrollTop = 0
      },       
      getResults(page = 1) {
        this.request_result_agency({page : page}) 
      },
      selectExport(){        
        this.$store.dispatch("result/select_export_isSelect", true)
        this.request_result_agency() 
      },
      clearExport(){
        this.$store.dispatch("result/select_export_isSelect", false)
        this.$store.dispatch("result/select_export", [])
        this.request_result_agency()
      },
      fullScreen(){
        const routeData = this.$router.resolve({query: Object.assign({}, this.$route.query, { zoom : true }) });
        window.open(routeData.href, '_blank');        
      }
  }, 
};
</script>

<style scoped>
</style>
